import { template as template_1bc3253a362541379c19e20fc526c1ff } from "@ember/template-compiler";
const WelcomeHeader = template_1bc3253a362541379c19e20fc526c1ff(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
