import { template as template_544544ee418a4b49b2beed79e897e814 } from "@ember/template-compiler";
const SidebarSectionMessage = template_544544ee418a4b49b2beed79e897e814(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
