import { template as template_444fef3c89644ca8b815bb73f5139531 } from "@ember/template-compiler";
const FKLabel = template_444fef3c89644ca8b815bb73f5139531(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
